import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import AssetSelector from 'common/components/AssetSelector';
import { RENDER_STYLE_CARD } from 'common/components/AssetBrowser/lib/constants';

class AssetSelectorDemo extends Component {

  render() {
    const assetSelectorProps = {
      additionalTopbarComponents: [],
      assetSelector: true,
      baseFilters: {},
      closeOnSelect: false,
      globalFilterDatasetUid: null,
      onAssetSelected: (args) => alert(`selected! - args = ${JSON.stringify(args)}`),
      onClose: (args) => alert(`closed! - args = ${JSON.stringify(args)}`),
      openInNewTab: true,
      renderInModal: false,
      renderStyle: RENDER_STYLE_CARD,
      resultsPerPage: 6,  // 6 is default for card mode, 10 for table mode.
      showBackButton: true,
      showGlobalFilterBarFilter: false, // When true, then globalFilterDatasetUid must also be set to a UID
      showSearchField: true
    };

    return (
      <div>
        <div className="styleguide-example">
          <AssetSelector {...assetSelectorProps} />
        </div>

        <h2 className="styleguide-header">Code example</h2>
        <div className="styleguide-code-example">
          <pre>
            {`
const assetSelectorProps = ${JSON.stringify(assetSelectorProps, null, 2)};

<AssetSelector {...assetSelectorProps} />
            `}
          </pre>
        </div>
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(AssetSelectorDemo),
    document.getElementById('component-demo')
  );
});
